@import url('./variables.less');

// use portals to mount to #portal to avoid position issues on dark alpha mode
.dark-alpha-theme {
  filter: invert(90%) hue-rotate(180deg) saturate(120%) brightness(95%);
  // restore color critical components to original color by inverting again
  .color-critical,
  .ant-progress-bg,
  .ant-drawer-mask,
  img {
    filter: invert(100%) hue-rotate(180deg) saturate(100%) brightness(100%);
  }
  // workaround for glitching in chrome
  .ant-modal-content,
  .ant-drawer-body {
    backface-visibility: hidden;
  }
}

body {
  &.alpha-theme:not(img, video) {
    .conviva-app-loader > div[data-name='Experience Insights'],
    .conviva-app-loader > div[data-name='Ad Experience'],
    .conviva-app-loader > div[data-name='ECO'],
    #pulse-main {
      background-color: #1f1e1f;
    }

    #pulse-content,
    .ant-message,
    .ant-notification,
    #pendo-base > div,
    ul.c-select-options-wrapper,
    div.c-modal-wrapper,
    .echarts-tooltip,
    .react-contexify {
      .dark-alpha-theme();
    }

    // google map darm mode
    .map-content > div:fullscreen {
      filter: invert(100%) hue-rotate(180deg) saturate(100%) brightness(95%);

      img {
        filter: none;
      }
    }
  }
}

#ifc-portals {
  .explore-drawer {
    .ant-drawer-close {
      position: absolute;
      right: 0;
    }
  }

  .kpi-settings-drawer {
    .ant-drawer-close {
      position: absolute;
      right: 0;
    }
  }
}

html,
body,
#pulse-main {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

/* stylelint-disable-next-line no-duplicate-selectors */
body {
  font-family: 'Open Sans', sans-serif !important;
  line-height: 1.5;
  color: #000;
  font-size: 14px;

  // to prevent body mounted echarts tooltips from adding scroll
  overflow: hidden;

  svg {
    display: inline-block;
  }

  .ant-dropdown-trigger > .anticon.anticon-down,
  .ant-dropdown-link > .anticon.anticon-down {
    display: inline-flex;
  }

  i.anticon {
    display: inline-flex;
  }


  #pulse-main {
    display: flex;
    flex-direction: column;

    > #pulse-content {
      display: flex;
      flex-grow: 1;
      overflow: hidden;
      padding: 0 5px;
      flex-direction: row-reverse;
      z-index: 0;
      contain: layout;

      .conviva-app-loader {
        flex-grow: 1;
        overflow: hidden;

        > div {
          position: relative;
          width: 100%;
          height: 100%;
          overflow: auto;
        }
      }
    }
  }

}

.app-scroll-wrap {
  visibility: hidden;
  overflow: auto;
  width: 100%;
  height: 100%;

  &:hover {
    visibility: visible;
  }
}

.app {
  display: flex;
  flex-direction: column;
  visibility: visible;
  // overflow: auto;
  width: 100%;
  height: 100%;
}

.container {
  padding: 8px;
}

.widget {
  background-color: #fff;
  padding: @widget-y @widget-x;
  margin-bottom: 0.4rem;
}

.row::after,
.tr::after {
  content: '';
  clear: both;
  visibility: hidden;
  display: block;
  height: 0;
}

.streched {
  position: absolute;
  inset: 0;
}

.testid {
  [data-testid] {
    border: 1px solid tomato;
  }
}

h4 {
  font-weight: 400;
}
