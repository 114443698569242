// @colorDefault: #588fe6;
@colorDefault: #1890ff;
@colorHover: #40a9ff;
@colorActive: #096dd9;
@colorDisabled: #aaa;

.button {
  // Layout
  position: relative;
  display: inline-block;
  box-sizing: border-box;

  // Colors
  color: @colorDefault;
  background-color: #fff;
  border: 1px solid @colorDefault;
  border-radius: 5px;

  // Space
  padding: 0 1em;
  margin: 0 10px;

  // Text
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;

  // Interaction
  user-select: none;
  cursor: pointer;

  // Transition
  @transition: color .3s, background-color .3s, border-color .3s;
  -webkit-transition: @transition;
  -moz-transition: @transition;
  transition: @transition;

  &:hover {
    // outline: 3px solid rgba(24, 144, 255, 0.06);
    border-color: @colorHover;
    color: @colorHover;
  }

  &:active {
    // outline: 3px solid rgba(24, 144, 255, 0.06);
    border-color: @colorActive;
    color: @colorActive;
  }

  &.large {
    font-size: 16px;
    line-height: 32px;
  }

  &.small {
    line-height: 22px;
  }

  &.dropdown {
    padding-right: 2em;

    &:after {
      position: absolute;
      top: .7em;
      right: .7em;
      z-index: 1;

      display: block;
      content: '';
      width: .5em;
      height: .5em;

      border-style: solid;
      border-color: inherit;
      border-width: 0 0 .1em .1em;

      transform: rotate(-45deg);
    }

    &.small:after {
      top: .5em;
    }
  }

  &.left {
    margin-right: 0;
  }

  &.right {
    margin-left: 0;
  }

  &.primary {
    color: #fff;
    background-color: #40a9ff;
    border-color: #40a9ff;
  }

  &.secondary {
    color: #464646;
    border-color: #464646;
  }

  &[disabled] {
    opacity: .5;
  }
}

// TODO: vision-cleanup-tag
.percentage-switcher.ant-radio-group {
  height: 30px;
  line-height: 30px;
  box-sizing: content-box;

  // Colors
  color: @colorDefault;
  background-color: #fff;
  border: 1px solid @colorDefault;
  border-radius: 5px;

  // Transition
  @transition: color .3s, background-color .3s, border-color .3s;
  -webkit-transition: @transition;
  -moz-transition: @transition;
  transition: @transition;

  .ant-radio-button-wrapper {
    border-radius:5px;
    height: inherit;
    line-height: inherit;
    border-width: 0;
    padding: 0 8px;
    min-width: 1em;
    text-align: center;
    box-sizing: content-box;
  }

  .ant-radio-button-wrapper-checked {
    background-color: #e9f3fd;
    box-shadow: none;

    &:focus,
    &:focus-within {
      outline: none;
    }
  }
}
