// :global symbol is from cssloader plugin.
// Wrapping anything inside :global prevents css-loader from
// renaming ids(?) and class names
:global {
  @import "./buttons.less";
  @import "./variables.less";
  @import "./common.less";
  @import "./specific.less";
  @import "./shorthand.less";
}

body {
  font-family: "Roboto", sans-serif !important;
}
