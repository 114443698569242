@import url('variables.less');

/* custom style for ant icons */
.if-icon {
  svg {
    width: 1.2em;
    height: 1.2em;
    margin-right: 0.5em;
  }

  &.anticon-ellipsis {
    transform: rotate(90deg);
    vertical-align: bottom;
  }

  &.if-icon-disabled {
    color: @grey-5;

    svg:hover {
      cursor: not-allowed;
      color: currentcolor;
    }
  }
}

.initial-loader {
  position: absolute;
  margin-top: calc(10% + 3rem);
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
}

.noscript {
  background-color: white;
  padding: 1rem;
  border-radius: 10px;
  color: #ff7875;
}

/* Header Layout */

.header-layout {
  display: flex;
  margin: 8px 5px 0;
  background: #F5F5F5;
  border-bottom: 1px solid #e5e5e5;

  .left {
    overflow-x: hidden;
    flex-grow: 1;
  }

  .right {
    flex-shrink: 0;
  }
}

// added to move form required * indicator from left side of label to the right
.ant-legacy-form-item-required::after {
  display: inline-block !important;
  margin-right: 4px !important;
  color: #ff4d4f !important;
  font-size: 14px !important;
  font-family: SimSun, sans-serif !important;
  line-height: 1 !important;
  content: '*' !important;
}

.ant-legacy-form-item-required::before {
  display: none !important;
}

.ant-form-item-required::before {
  position: absolute;
  right: -0.9em;
  top: 0.3em;
}

.ant-form label {
  font-weight: 500;
  color: black;
}

.ant-form-vertical .ant-form-item {
  padding-bottom: 12px;
}

@media (width <= 480px) {
  .ant-calendar-range {
    width: 320px;
  }

  .ant-calendar-range-part {
    width: 100%;
  }
}

.ant-calendar-input-wrap,
.ant-calendar-range-middle,
.ant-calendar-prev-year-btn,
.ant-calendar-next-year-btn {
  display: none !important;
}

.info-popover {
  .ant-popover-inner {
    padding: 0;
  }
}

.sf-highlight {
  border-bottom: 3px solid #bd10e0;
}

.table-zebra-odd {
  background-color: #f8f8f8;
}

.table-zebra-even {
  background-color: unset;
}

.ant-modal .ant-modal-title {
  font-weight: 500;
}
